import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, Check, X, Heart, ChevronDown, ChevronUp } from 'lucide-react';

interface GratitudeEntry {
  id: string;
  content: string;
  createdAt: string;
  updatedAt: string;
}

const STORAGE_KEY = 'heal_gratitude_list';

export function GratitudeList() {
  const [entries, setEntries] = useState<GratitudeEntry[]>([]);
  const [newEntry, setNewEntry] = useState('');
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editContent, setEditContent] = useState('');
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (stored) {
      setEntries(JSON.parse(stored));
    }
  }, []);

  const saveEntries = (updatedEntries: GratitudeEntry[]) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedEntries));
    setEntries(updatedEntries);
  };

  const handleAdd = () => {
    if (!newEntry.trim()) return;

    const newItem: GratitudeEntry = {
      id: crypto.randomUUID(),
      content: newEntry.trim(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    saveEntries([...entries, newItem]);
    setNewEntry('');
  };

  const handleEdit = (id: string) => {
    const entry = entries.find(e => e.id === id);
    if (entry) {
      setEditingId(id);
      setEditContent(entry.content);
    }
  };

  const handleSaveEdit = () => {
    if (!editingId || !editContent.trim()) return;

    const updated = entries.map(e =>
      e.id === editingId
        ? { ...e, content: editContent.trim(), updatedAt: new Date().toISOString() }
        : e
    );

    saveEntries(updated);
    setEditingId(null);
    setEditContent('');
  };

  const handleDelete = (id: string) => {
    const updated = entries.filter(e => e.id !== id);
    saveEntries(updated);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-gradient-to-br from-pink-50 via-red-50 to-pink-50 rounded-xl p-8 shadow-lg space-y-6">
        <div className="text-center">
          <div className="flex items-center justify-center gap-2 mb-4">
            <Heart className="w-8 h-8 text-red-500" />
            <h2 className="text-2xl font-bold text-gray-900">Gratitude Journal</h2>
          </div>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Express gratitude daily to amplify your healing journey. What are you thankful for today?
          </p>
        </div>

        <div className="space-y-6">
          <div className="space-y-3">
            <div className="flex gap-2">
              <input
                type="text"
                value={newEntry}
                onChange={(e) => setNewEntry(e.target.value)}
                placeholder="I am grateful for..."
                className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-400 focus:border-transparent"
                onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
              />
              <button
                onClick={handleAdd}
                disabled={!newEntry.trim()}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              >
                <Plus className="w-4 h-4" />
                Add
              </button>
            </div>
          </div>

          <div className="space-y-4">
            {entries.length === 0 ? (
              <div className="text-center py-8 bg-white rounded-xl">
                <Heart className="w-12 h-12 text-red-300 mx-auto mb-3" />
                <p className="text-gray-500">
                  Start your gratitude practice by adding your first entry
                </p>
              </div>
            ) : (
              entries.map((entry, index) => (
                <div
                  key={entry.id}
                  className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow group"
                >
                  {editingId === entry.id ? (
                    <div className="flex items-center gap-2">
                      <input
                        type="text"
                        value={editContent}
                        onChange={(e) => setEditContent(e.target.value)}
                        className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-red-400 focus:border-transparent"
                        onKeyPress={(e) => e.key === 'Enter' && handleSaveEdit()}
                      />
                      <button
                        onClick={handleSaveEdit}
                        className="p-2 text-green-600 hover:text-green-700"
                      >
                        <Check className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => setEditingId(null)}
                        className="p-2 text-gray-600 hover:text-gray-700"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <span className="text-red-400 font-medium">{index + 1}.</span>
                        <p className="text-gray-700">{entry.content}</p>
                      </div>
                      <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        <button
                          onClick={() => handleEdit(entry.id)}
                          className="p-1 text-gray-400 hover:text-gray-600"
                        >
                          <Edit2 className="w-4 h-4" />
                        </button>
                        <button
                          onClick={() => handleDelete(entry.id)}
                          className="p-1 text-red-400 hover:text-red-600"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}