import React from 'react';
import { LogOut, Users, Home, BookOpen, Coins, Heart } from 'lucide-react';

interface NavigationProps {
  showArchive: boolean;
  showVictories: boolean;
  showTraining: boolean;
  showTithe: boolean;
  showGratitude: boolean;
  step: string;
  onResetView: () => void;
  onResetThread: () => void;
  onShowArchive: () => void;
  onShowVictories: () => void;
  onShowTraining: () => void;
  onShowTithe: () => void;
  onShowGratitude: () => void;
  onLogout: () => void;
}

export function Navigation({
  showArchive,
  showVictories,
  showTraining,
  showTithe,
  showGratitude,
  step,
  onResetView,
  onResetThread,
  onShowArchive,
  onShowVictories,
  onShowTraining,
  onShowTithe,
  onShowGratitude,
  onLogout
}: NavigationProps) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex flex-wrap gap-4 items-center justify-between">
      <div className="flex items-center gap-3">
        <img 
          src="https://i.imgur.com/47w7oTV.png" 
          alt="H.E.A.L. Logo" 
          className="h-8 w-auto"
          loading="eager"
        />
        <h1 className="text-2xl font-bold text-gray-900">
          {showArchive ? 'Archive' : 
           showVictories ? 'Victory Room' :
           showTraining ? 'Training Videos' :
           showTithe ? 'Tithe' :
           showGratitude ? 'Gratitude Journal' : 'H.E.A.L.'}
        </h1>
      </div>
      <div className="flex flex-wrap gap-3">
        {(showArchive || showVictories || showTraining || showTithe || showGratitude || step !== 'initial') && (
          <button
            onClick={() => {
              onResetView();
              onResetThread();
            }}
            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
          >
            Back to Journal
          </button>
        )}
        {!showArchive && step === 'initial' && (
          <button
            onClick={onShowArchive}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            View Archive
          </button>
        )}
        {!showVictories && step === 'initial' && (
          <button
            onClick={onShowVictories}
            className="px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-700"
          >
            Victory Room
          </button>
        )}
        {!showTraining && step === 'initial' && (
          <button
            onClick={onShowTraining}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center gap-2"
          >
            <BookOpen className="w-4 h-4" />
            Training
          </button>
        )}
        {!showTithe && step === 'initial' && (
          <button
            onClick={onShowTithe}
            className="px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-700 flex items-center gap-2"
          >
            <Coins className="w-4 h-4" />
            Tithe
          </button>
        )}
        {!showGratitude && step === 'initial' && (
          <button
            onClick={onShowGratitude}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center gap-2"
          >
            <Heart className="w-4 h-4" />
            Gratitude
          </button>
        )}
        <a
          href="https://www.livefromyourjoy.com"
          target="_blank"
          rel="noopener noreferrer"
          className="px-4 py-2 bg-cyan-600 text-white rounded-md hover:bg-cyan-700 flex items-center gap-2"
        >
          <Users className="w-4 h-4" />
          Private Community
        </a>
        <button
          onClick={onLogout}
          className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 flex items-center gap-2"
        >
          <LogOut className="w-4 h-4" />
          Logout
        </button>
      </div>
    </div>
  );
}